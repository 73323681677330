.Filter{
    margin-top: 20px; 
    padding: 20px; 
    background-color: #d2d2d2;
    border-radius: 1%;
     
}
.Filter Button{
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: #69B1FF;
    color: #ffffff;
    width: 100%;
}
.Filter Button:hover{
    color: #128A8C;
}
.filter-cascader, .filter-slider {
    width: 100%;
}
.Row{
    margin-bottom: 50px;
    margin-top: 50px;
}
/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
@media (max-width: 768px) {
    .FilterName1{
        font-size: 18px !important;
    }
    .FilterName{
        font-size: 16px !important;
    }
    .Filter {
        padding: 10px;
    }
}