.SuccessPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: flex-start; /* Изменяем на flex-start, чтобы содержимое выравнивалось по началу страницы */
    padding-bottom: 50px; 
  }
  
  /* Стили для верхней части страницы, включая поисковую строку и ссылки профиля/настройки */
  .TopBar  {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .SearchBars {
    margin-left: 136%; 
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2%;
  }
  
  .profile-settings {
    display: flex;
    align-items: center;
  }
  
  /* Стили для карусели популярных книг */
  .popularBooks {
    width: 60%; /* Ширина карусели */
    margin: 20px 0; /* Отступ сверху и снизу */
    text-align: center;
  }
  
  /* Улучшение визуальной привлекательности ссылок */
  a {
    margin: 0 10px;
    color: #007bff; /* Синий цвет для ссылок */
    text-decoration: none; /* Убираем подчеркивание */
  }
  
  a:hover {
    text-decoration: underline; /* Подчеркивание при наведении */
  }
  
  /* Стилизация кнопки поиска и иконок настроек */
  button, .anticon {
    cursor: pointer; /* Курсор в виде указателя */
  }
  
  /* Добавляем внешние отступы для аватара */
  .Avatar {
    margin-right: 10px; 
  }  
 
.SuccessPage .searchResults {
  margin-top: 20px;
  margin-bottom: 20px; 
}
