.custom-card {
    display: flex;
    flex-direction: column; /* Распологаем в столбец */
    justify-content: space-between; /* Распределяем пространства между элементами */
    height: 100%; /* Задаем высоту карточки */
    
  }
  
  .custom-card .ant-card-body {
    flex-grow: 1; /* Контент карточки занимает все доступное пространство */
    display: flex;
    flex-direction: column; /* Распологаем в столбец */
    justify-content: space-between; /* Распределяем пространства между содержимым и действиями */
  }
  
  .custom-card .ant-card-actions {
    margin-top: auto; /* Отодвигаем действия вниз */
  }
  
  .custom-card2 {
    display: flex;
    flex-direction: column; /* Распологаем в столбец */
    justify-content: space-between; /* Распределяем пространства между элементами */
    height: 100%; /* Задаем высоту карточки */
    
  }
  
  .custom-card2 .ant-card-body {
    flex-grow: 1; /* Контент карточки занимает все доступное пространство */
    display: flex;
    flex-direction: column; /* Распологаем в столбец */
    justify-content: space-between; /* Распределяем пространства между содержимым и действиями */
  }
  
  .custom-card2 .ant-card-actions {
    margin-top: auto; /* Отодвигаем действия вниз */
  }
  
.Row{
    margin-bottom: 50px;
    margin-top: 50px;
}

.Row2{
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 50%;
}
/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
@media (max-width: 768px) {
  .Row2 {
      margin-left: 0;
  }

  .custom-card2 .ant-card-body {
    font-size: 12px; /* уменьшаем размер шрифта текста */
}
.BookTitle{
  font-size: 16px !important;
}
.custom-card2 .ant-btn {
    font-size: 12px; /* уменьшаем размер шрифта кнопок */
    padding: 4px 8px; /* уменьшаем размер кнопок */
}

.custom-card2 .ant-btn > span {
    font-size: 12px; /* уменьшаем размер текста внутри кнопок */
}

.custom-card2 .ant-card-meta-description {
    font-size: 12px; /* уменьшаем размер шрифта описания */
}

.custom-card2 .ant-card-meta-title {
    font-size: 16px !important; /* уменьшаем размер шрифта заголовка */
}
}
