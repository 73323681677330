.Card_title{
    text-align: center;
    margin-bottom: 2%;
    width: 56%;
    height: 53%;
    margin-left: 22%;
}
.Card_content{
    text-align: left;
    margin-bottom: 2%;
}
.Button_sbrosit{
    width:60%;
}
.input_modal{
    margin-bottom: 2%;
}
.button_modal{
    margin-bottom: 2%;
    margin-left: 40%;
}
/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
@media (max-width: 768px) {
    .Card_title {
        width: 90%;
        height: auto;
        margin-left: 5%;
    }
    .Card_content {
        text-align: left;
    }
    .Button_sbrosit {
        width: 80%;
        margin-left: 10%;
    }
    .input_modal {
        width: 100%;
    }
    .button_modal {
        width: 80%;
        margin-left: 10%;
    }
}