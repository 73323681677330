.ProjectsTitle {
    text-align: center;
    margin-bottom: 2%;
}

.OurProjectPage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.OurProjects {
    display: flex;
    width: 45%;
    margin-bottom: 3%;
    margin-left: 3%;
}
.ant-descriptions-title{
    text-align: center;
}
/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
@media (max-width: 768px) {
    .OurProjectPage {
        flex-direction: column;
        align-items: center;
    }

    .OurProjects {
        width: 90%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .ant-descriptions-item-label {
        font-size: smaller !important;
    }

    .ant-descriptions-item-content {
        font-size: smaller !important;
    }

    .ant-descriptions-header {
        font-size: larger !important;
    }
}
