.Contacts{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;
    max-width: 63%; 
    margin-left: 14%;
}
.ContactsForm{ 
    text-align: center;
    max-width: 600px; /* Ограничиваем максимальную ширину формы */
    padding: 20px; /* Добавляем отступы */
    border-radius: 8px; /* Скругленные углы */
   
}
.ContactsItem{
    max-width: 100%;
}
/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
@media (min-width: 768px) {
    .Contacts {
        width: 90%;
    }
    .ContactsForm {
        width: 100%;
    }
    .ContactsItem{
        width: 50%;
    }
}

