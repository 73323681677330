.modal-content {
    color: #444;
    background-color: #f9f9f9; 
    padding: 15px; 
    border-radius: 5px; 
    margin-bottom: 10px; 
}

.modal-title {
    font-weight: bold; 
    margin-bottom: 5px; 
}

.modal-value {
    margin-left: 10px; 
}
.ant-modal-title{
    text-align: center;
}
.Price{
    font-weight: bold;
    font-style: italic;
    text-align: center;
    font-size: 20px; 
}