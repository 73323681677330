.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 120px;
    background-color: #fafcfc;
    text-align: center;  
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-text {
    margin: 5px 0 0 15px;
  }
  
  .footer-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    align-items: center;
  }
  
  .footer-icon {
    font-size: 24px;
  }

/* Медиа-запросы для устройств с максимальной шириной 768px (планшеты и мобильные) */
  @media (min-width: 768px) {
    .footer {
      flex-direction: row; /* Распологаем в строку */
      justify-content: space-between;
      text-align: left;
      width: 100%;
    }
  
    .footer-section {
      flex-direction: row;
      align-items: center;
    }
  
    .footer-text {
      margin: 0 15px;
    }
  
    .footer-icons {
      margin-top: 0;
    }
  
    .footer-icon {
      font-size: 30px;
    }
  }
  